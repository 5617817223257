<template>
  <div class="viewer">
    <div class="content">
      <div class="pdf-content">
        <pdf
          v-for="i in numPages"
          :key="i"
          :src="resultPdfUrl"
          :page="i"
          style="width: 100%;"
          @page-loaded="handlePageLoaded"
        />
      </div>
      <div v-if="showSignature && pdfUrl && pdfUrl.length > 0" class="contract-operation">
        <van-button class="operation-btn" block @click="handleSignature">去签名</van-button>
        <div class="split-line" />
        <van-button class="operation-btn" block @click="handleUpload">上传合同</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'
import { Button, Toast } from 'vant'
import { getContract, getSignContract, signupContract } from "@/request/api/contract";

export default {
  name: 'Contract',
  components: {
    pdf,
    VanButton: Button
  },
  data() {
    return {
      pdfUrl: '',
      resultPdfUrl: '',
      token: '',
      signKey: '',
      initialPdfUrl: '',
      signatureUrl: '',
      numPages: 0,
      uni: null,
      showSignature: false,
      uploadBtnDisabled: false
    }
  },
  created() {
    this.initSdk()
    this.initPdfUrl()
    this.$bus.$on('onSignatureSuccess', (url) => {
      this.signatureUrl = url
      this.getSignContract()
    })
  },
  activated() {
    this.initPdfUrl()
  },
  methods: {
    /**
     * 根据环境配置uniapp api
     */
    initSdk() {
      const userAgent = navigator.userAgent
      if (/miniProgram/i.test(userAgent) && /micromessenger/i.test(userAgent)) {
        this.uni = this.$wx.miniProgram
      } else {
        this.uni = this.$uni
      }
    },
    /**
     * 初始化pdfUrl
     */
    async initPdfUrl() {
      const { signKey, pdfUrl, token, signature } = this.$route.query
      if ((signKey && this.signKey === signKey) || (pdfUrl && pdfUrl === this.pdfUrl)) {
        return
      }
      this.showSignature = !(signature && signature === '0');
      if (pdfUrl) {
        this.pdfUrl = pdfUrl
        this.initialPdfUrl = pdfUrl
        this.token = token
      } else if (signKey) {
        this.signKey = signKey
        await this.getContract()
      } else {
        Toast.fail({
          message: '参数不能为空'
        })
        return
      }
      this.loadPdfUrl()
    },
    /**
     * 从路由获取pdfUrl
     */
    loadPdfUrl() {
      if (this.pdfUrl && this.pdfUrl.length > 0) {
        Toast.loading({
          message: '合同加载中...',
          duration: 0
        })
        this.getNumPages()
      } else {
        Toast.fail({
          message: '请传入合同'
        })
      }
    },
    /**
     * 获取pdf总页数
     */
    getNumPages() {
      this.resultPdfUrl = pdf.createLoadingTask({
        url: this.pdfUrl,
        // cMapPacked: true,
        // cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.14.305/cmaps/',
        CMapReaderFactory
      })
      this.resultPdfUrl.promise.then(pdf => {
        this.numPages = pdf.numPages
      }).catch(() => {
        Toast.clear()
        Toast.fail({
          message: '合同加载失败'
        })
      })
    },
    /**
     * pdf加载事件
     * @param page 当前加载成功页数
     */
    handlePageLoaded(page) {
      if (page >= this.numPages) {
        Toast.clear()
      }
    },
    /**
     * 获取合同url
     */
    async getContract() {
      await getContract({ signKey: this.signKey }).then(({ contractPath }) => {
        this.pdfUrl = contractPath
        this.initialPdfUrl = contractPath
      })
    },
    /**
     * 获取签名合同url
     */
    getSignContract() {
      getSignContract({
        contractUrl: this.initialPdfUrl,
        signNameUrl: this.signatureUrl,
        signKey: this.signKey,
        token: this.token
      }).then(data => {
        this.pdfUrl = data
        if (!this.signKey || this.signKey.length <= 0) {
          this.uni.postMessage({
            data: {
              pdfUrl: this.pdfUrl,
              signatureUrl: this.signatureUrl
            }
          })
        }
        this.loadPdfUrl()
      })
    },
    /**
     * 跳转签名
     */
    handleSignature() {
      if (!this.pdfUrl) {
        Toast({
          message: '合同不能为空',
          duration: 1000
        })
        return
      }
      this.$router.push({
        path: '/contract/signature'
      })
    },
    /**
     * 上传合同
     */
    handleUpload() {
      if (this.uploadBtnDisabled) {
        Toast.fail('请勿重复提交')
        return
      }
      if (this.signatureUrl && this.signatureUrl.length > 0) {
        if (this.initialPdfUrl === this.pdfUrl) {
          Toast({
            message: '请等待合同加载完成',
            duration: 1000
          })
          return
        }
        if (this.signKey && this.signKey.length > 0) {
          signupContract({ contractPath: this.pdfUrl, signPic: this.signatureUrl, signKey: this.signKey }).then(() => {
            this.uploadBtnDisabled = true
            Toast({
              message: '上传成功',
              duration: 1000
            })
          })
        } else {
          Toast({
            message: '上传成功',
            duration: 500,
            onClose: () => {
              this.uni.navigateBack()
            }
          })
        }
      } else {
        Toast({
          message: '请先签名',
          duration: 1000
        })
      }
    }
  }
}
</script>

<style scoped>
.viewer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.content {
  flex: 1;
  max-width: 1000px;
}
.pdf-content {
  padding-bottom: calc(60px + env(safe-area-inset-bottom));
}
.contract-operation {
  z-index: 99;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45px;
  margin-bottom: env(safe-area-inset-bottom);
  background-color: #FFC200;
  display: flex;
  align-items: center;
}
.operation-btn {
  width: calc(50% - 1px);
  height: 45px;
  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #FFC200;
  border: none;
  border-radius: 0;
}
.split-line {
  width: 2px;
  height: 36px;
  background-color: #FFFFFF;
  border-radius: 1px;
}
</style>
